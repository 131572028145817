<template>
  <b-card-code title="Import Files">
    <div class="d-flex justify-content-between flex-wrap">
      <b-form-group>
        <b-input-group>
          <b-button variant="outline-primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :to="{ name: 'persons', params: { idEvent: this.idEvent } }">
            <!-- , params: { idEvent: data.idEvent} -->
            <feather-icon icon="ArrowLeftCircleIcon" class="mr-0 mr-sm-50"></feather-icon>
            Go Back
          </b-button>
        </b-input-group>
      </b-form-group>
    </div>
    <div>
      <h3> {{ this.event.Aforo_name }}</h3>
      <h5>{{ this.event.pkid_mxs_event }} -> {{ this.event.event_name }} </h5>
      <label :hidden="hiddenComponent" for="extension" class="mt-1">Only accept Excel files</label>
      <div :hidden="hiddenComponent">
        <b-form-file size="lg" id="extension" accept=".xlsx" type="file" @change="onChange" />
      </div>
      <xlsx-read :file="file">
        <xlsx-json @parsed="parsFunc">
          <template #default="{ collection }">
            <div>
              <b-button class="mt-1" variant="primary" @click="saveData(filteredCollection)" :hidden="hiddenComponent">Verify
                and Save Data</b-button>
            </div>
          </template>
        </xlsx-json>
        <div :hidden="hideXLS">
          <xlsx-table class="mt-1" />
        </div>
      </xlsx-read>
      <b-card-code no-body>
        <!-- table -->
        <b-table 
          sticky-header=700px
          responsive 
          :items="dataCollection"
          selectable 
          :select-mode="selectMode"
          class="mb-0">
          <template #cell(OK_DNI)="data">
            <b-badge v-if="data.value==='KO'" :variant="statusLabel[1][data.value]">
              {{ statusLabel[0][data.value] }}
            </b-badge>
            <div v-else>
              {{ data.value }}
            </div>
          </template>
          <template #cell(OK_AccesSCA)="data">
            <b-badge v-if="data.value==='KO'" :variant="statusLabel[1][data.value]">
              {{ statusLabel[0][data.value] }}
            </b-badge>
            <div v-else>
              {{ data.value }}
            </div>
          </template>
          <template #cell(Error_description)="data">
            <b-badge v-if="data.value==='KO'" :variant="statusLabel[1][data.value]">
              {{ statusLabel[0][data.value] }}
            </b-badge>
            <div v-else v-html="data.value" style="width: 300px; white-space: pre-line;"></div>
          </template>
        </b-table>
      </b-card-code>
    </div>
  </b-card-code>
</template>

<script>
import { XlsxRead, XlsxTable, XlsxSheets, XlsxJson, XlsxWorkbook, XlsxSheet, XlsxDownload } from "../../../node_modules/vue-xlsx/dist/vue-xlsx.es.js";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BInputGroup, BFormGroup, BFormInput, BFormSelect, BButton, BFormFile, BTable, BBadge } from "bootstrap-vue";
import router from "@/router";
import axios from "@/libs/axios";
import { decryptString } from '@/libs/crypto'

export default {
  components: {
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    BCardCode,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BFormFile,
    BTable,
    BBadge,
  },
  data() {
    return {
      disabledComponents: false,
      hiddenComponent: false,
      hideXLS: false,
      file: null,
      dataCollection: [],
      filteredCollection: [],
      dooracces: [],
      event: null,
      idEvent: router.currentRoute.params.idEvent,
      verified: true,
      selectMode: 'single',
      statusLabel: [
        {
          KO: "KO",
        },
        {
          KO: "light-danger",
        },
      ],      
      headerData: ["Nom_titular", "Cognom1_titular", "Cognom2_titular", "DOI_Type", "DNI", "email", "Data_naixement", "fkid_accreditation_company", "Empresa_nom", "Matricula", "Cod_Barras", "AccesSCA"],
    };
  },
  computed: {
    lengData: function () {
      return this.headerData.length;
    }
  },
  methods: {
    onChange(event) {      
      this.hideXLS = false;
      this.dataCollection = [];
      this.filteredCollection = [];
      this.file = event.target.files ? event.target.files[0] : null;
    },
    saveData(collection) {
      this.hideXLS = true;
      this.dataCollection = collection;
      this.verifyData();
      // console.log(this.verified);
      if (this.verified) {
        // console.log(this.dataCollection);

        axios
          .post("/accred-doc-detailed", this.filteredCollection)
          .then((result) => {
            this.hiddenComponent = true;
            this.dataCollection = this.filteredCollection;
            const variant = "success";
            this.$bvToast.toast("Excel imported correctly", {
              title: `Update`,
              variant,
              solid: true,
            });
          })
          .catch((error) => {
            // console.log(error);
            const variant = "danger";
            this.$bvToast.toast("Excel not imported \n" + error + "\n Verify that the data does not exist in the database.", {
              title: "Failed to import",
              variant,
              solid: true,
            });
          });
      } else {
        const variant = "danger";
            this.$bvToast.toast("Excel not imported", {
              title: "Failed to import",
              variant,
              solid: true,
            });
      }
    },
    verifyData() {
      this.verified = true;
      this.filteredCollection = this.dataCollection.map((element) => {

        const reorderedElement  = {
          OK_DNI: "OK",
          OK_AccesSCA: "OK",
          Error_description: "",
          ...element, // Copiar las propiedades originales del elemento
          Temporada: this.event.Temporada,
          ID_Aforo: this.event.ID_Aforo,
          fkid_mxs_event: this.event.pkid_mxs_event,
          fkid_persona: '',
          ID_usuari_creacio: decryptString(localStorage.getItem('idUser')),
          metode_creacio: 'excel_import',
          fkid_accreditation_status: "000000",
          DNI: element.DNI.toString().trim()
        };

        if (element.DOI_Type === "DNI") {
          const dni = element.DNI.toString().trim();
          let numero;
          let letr;
          let letra;
          let expresion_regular_dni;

          expresion_regular_dni = /^\d{8}[a-zA-Z]$/;

          if (expresion_regular_dni.test(dni) == true) {
            numero = dni.substr(0, dni.length - 1);
            letr = dni.substr(dni.length - 1, 1);
            numero = numero % 23;
            letra = "TRWAGMYFPDXBNJZSQVHLCKET";
            letra = letra.substring(numero, numero + 1);
            if (letra != letr.toUpperCase()) {
              console.log("DNI erroni, la lletra no és correcta");
              reorderedElement.OK_DNI = "KO";
              reorderedElement.Error_description += "Invalid DNI letter.<br>";
              this.verified = false;
            } else {
              // console.log('DNI correcte')
            }
          } else {
            // console.log('DNI erroni, format no valid')
            reorderedElement.OK_DNI = "KO";
            reorderedElement.Error_description += "Invalid DNI format.<br>";
            this.verified = false;
          }
        }

        if ((element.DNI != "") && (element.Nom_titular!= "") && (element.Cognom1_titular!= ""))  {
          
        } else {
          // console.log('DNI erroni, format no valid')
          reorderedElement.OK_DNI = "KO";
          reorderedElement.Error_description += "DNI, Nom_titular and Cognom1_titular cannot be empty.<br>";
          this.verified = false;
        }

        if (this.dooracces.includes(element.AccesSCA) ||
          element.AccesSCA === "" ||
          element.AccesSCA === undefined ) {
          // console.log("AccesSCA de l'element: " + element.AccesSCA);
        } else {
          // console.log(
          //   "AccesSCA de l'element: " +
          //   element.AccesSCA +
          //   " no coincideix amb cap AccesSCA"
          // );
          reorderedElement.OK_AccesSCA = "KO";
          reorderedElement.Error_description +=  "AccesSCA cannot be empty or AccesSCA is not allowed.<br>";
          this.verified = false;
        }
        return reorderedElement;
      });

      this.dataCollection = this.filteredCollection.filter(element => element.Error_description !== "");
    },
    parsFunc(event) {
      /* this function loops throught elements in "xlsx" file and replaces empty cells with "" */
      let counter = 0;
      let filteredEvent = [];

      event.forEach((element) => {
        if (element['Nom_titular']) {
          let filteredElement = {};
          for (let it = 0; it < this.lengData; it++) {
            const column = this.headerData[it];
            if (element[column] === undefined) {
              filteredElement[column] = "";
            } else {
              filteredElement[column] = element[column];
            }
          }
          filteredEvent[counter] = filteredElement;
          counter++;
        }
      }); // end of forEach
      
      this.filteredCollection = filteredEvent;
    },
  },
  async created() {
    await axios
      .get("/event?pkid_mxs_event=" + router.currentRoute.params.idEvent)
      .then((results) => {
        this.event = results.data[0];
      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      });

    await axios
      .get("/dooracces?ID_Aforo=" + this.event.ID_Aforo)
      .then((results) => {
        results.data.forEach((element) => {
          this.dooracces.push(element.AccesSCA);
        });
      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      });
  },
  mounted(){
    if (localStorage.getItem("accessToken") === null) {
      this.$router.go(0)
    }
  },
};
</script>
